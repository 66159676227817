import { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, AutoSizer, ScrollSync } from "react-virtualized";
import { RemoveButton } from "../../../../../../components/Buttons";
import Icon from "../../../../../../components/Icon";
import styles from "./RenderFieldArray.module.scss";

function renderFieldArray(HeaderComponent, FieldComponent) {
  const RenderFieldArray = ({
    fields,
    meta,
    requiredFieldNames,
    defaultValues,
    selectedLanguages,
    removeTranslation,
    setTermsToDelete,
    ...rest
  }) => {
    useEffect(() => {
      if (fields.length === 0) fields.push(defaultValues || {});
    }, [fields, defaultValues, selectedLanguages]);

    const handleRemoveTranslation = (rowIndex) => {
      fields.remove(rowIndex);
      const [sourceSegment] = Object.values(fields.get(rowIndex));
      setTermsToDelete(sourceSegment);
      removeTranslation(rowIndex);
    };

    const headerCellRenderer = ({ columnIndex, key, style }) => {
      return (
        <div key={key} style={style}>
          <HeaderComponent
            languageCode={selectedLanguages[columnIndex]}
            isSource={columnIndex === 0}
            selectedLanguages={selectedLanguages}
            key={key}
            style={style}
            {...rest}
          />
        </div>
      );
    };

    const removeHeaderRenderer = ({ key, rowIndex, style }) => {
      return (
        <div key={key} style={{ ...style, width: "50px" }}>
          <RemoveButton onClick={() => handleRemoveTranslation(rowIndex)} />
        </div>
      );
    };

    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
      const languageCode = selectedLanguages[columnIndex];
      return (
        <div key={key} style={style}>
          <FieldComponent
            name={`translations[${rowIndex}]`}
            languageCode={languageCode}
            meta={meta}
            {...rest}
            addField={() => fields.push(defaultValues || {})}
            swapField={fields.swap}
            disableRemove={fields.length === 1}
          />
        </div>
      );
    };

    const getColumnWidth = (width) => () => {
      const minColumnWidth = 200;
      // subtract 45px from total width for the last column
      const calculatedWidth = Math.floor(
        (width - 50) / selectedLanguages.length
      );

      return Math.max(calculatedWidth, minColumnWidth);
    };

    const addField = (e) => {
      e.preventDefault();
      const index = fields.length - 1;
      const lastField = fields.get(index);

      // If no values in the last field or requiredFieldNames are missing, don't add a field
      if (
        Object.keys(lastField).length === 0 ||
        (requiredFieldNames &&
          requiredFieldNames.some((name) => !lastField.hasOwnProperty(name)))
      ) {
        return;
      }

      fields.push(defaultValues || {});
    };

    const rowHeight = 50;
    const gridHeight = fields.length * rowHeight;

    return (
      <div>
        <ScrollSync>
          {({ onScroll, scrollLeft, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: width - 50,
                  }}
                >
                  <div
                    className={styles.scrollBarLeft}
                    style={{ width: width - 50 }}
                  >
                    <Grid
                      cellRenderer={headerCellRenderer}
                      columnCount={selectedLanguages.length}
                      columnWidth={getColumnWidth(width)}
                      height={50}
                      rowCount={1}
                      rowHeight={rowHeight}
                      width={width - 50}
                      onScroll={onScroll}
                      scrollLeft={scrollLeft}
                      className={styles.removeScrollBar}
                    />
                    <Grid
                      cellRenderer={cellRenderer}
                      columnCount={selectedLanguages.length}
                      columnWidth={getColumnWidth(width)}
                      height={gridHeight}
                      rowCount={fields.length}
                      rowHeight={rowHeight}
                      width={width - 50}
                      onScroll={onScroll}
                      scrollLeft={scrollLeft}
                    />
                  </div>
                  <div style={{ width: 50 }}>
                    <div style={{ height: 50 }} />
                    <Grid
                      cellRenderer={removeHeaderRenderer}
                      columnCount={1}
                      columnWidth={50}
                      height={gridHeight}
                      rowCount={fields.length}
                      rowHeight={50}
                      width={50}
                      scrollTop={scrollTop}
                      className={styles.removeScrollBar}
                    />
                  </div>
                </div>
              )}
            </AutoSizer>
          )}
        </ScrollSync>
        <button onClick={addField} className={styles.newButton}>
          Add New
        </button>
      </div>
    );
  };

  RenderFieldArray.propTypes = {
    fields: PropTypes.object.isRequired,
    requiredFieldNames: PropTypes.arrayOf(PropTypes.string),
    defaultValues: PropTypes.object,
    selectedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    meta: PropTypes.shape({
      submitFailed: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
  };

  return RenderFieldArray;
}

export default renderFieldArray;
