import { createAssignmentsSuccess } from "../../../../../../../modules/assignments";
import { updateDeliverablesSuccess } from "../../../../../../../modules/deliverables";
import { convertDate, formatDateObject } from "../../../../../../../utils/date";
import { postGraphQL } from "../../../../../../../../utils/fetch";
import handleErrors from "../../../../../../../utils/handleErrors";
import { get } from "lodash";

const AI_WORKFLOW_NAMES = [
  "AI Creation (1 Client Review)",
  "AI Creation (2 Client Reviews)",
  "AI / AMT Localisation (1 Client Review)",
];

function getRate({
  deliverableRatesMap,
  quotedRates = {},
  deliverableId,
  personId,
  stageId,
  langCode,
  language,
}) {
  // first see if a quoted rate exists
  const { rateBandId } = deliverableRatesMap[deliverableId] || {};
  const quotedRate = get(quotedRates, [
    langCode,
    stageId,
    personId,
    rateBandId,
  ]);
  if (quotedRate) {
    return quotedRate;
  }

  // fallback to rate band rate
  return get(
    deliverableRatesMap,
    [deliverableId, stageId, langCode],
    language[personId].rate
  );
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function createAssignments(data, history, params, options) {
  const { assignees, deadlines, filteredProjectLanguages, randomised } = data;
  const {
    deliverableIds,
    stages,
    deliverables,
    deliverableRatesMap,
    quotedRates,
  } = options;
  const { projectId } = params;

  const assignmentsTree = {};
  const personDeliverables = {};
  let orderedAllocatedDeliverables = [];

  let previousProductionStageId = null;
  let currentProductionStageId = null;

  stages.forEach((stage, index) => {
    console.log("personDeliverables", personDeliverables);
    const stageId = stage.stageId;
    const isTrainingStage = stage.stageType === "Training";

    if (!personDeliverables[stageId]) {
      personDeliverables[stageId] = {};
    }
    // Get the previous production stage (i.e., not training)

    if (!isTrainingStage) {
      previousProductionStageId = currentProductionStageId;
      currentProductionStageId = stage?.stageId;
    }

    // build a tree object for the current stage
    if (!assignmentsTree[stageId]) {
      assignmentsTree[stageId] = {
        assignments: [],
        trainingDeliverableIds: [],
      };
    }

    const stageAllocations = [];
    let usedInCurrentStage = new Set();

    Object.keys(assignees?.[stageId] || [])
      .filter((langCode) => filteredProjectLanguages.has(langCode))
      .forEach((langCode) => {
        const people = assignees[stageId][langCode];
        let availableDeliverables =
          stage === stages[0]
            ? [...deliverableIds.unallocated[langCode]]
            : [...orderedAllocatedDeliverables];

        if (randomised[stageId] && index !== 0) {
          availableDeliverables = shuffleArray(availableDeliverables);
        }

        // Shuffle available deliverables if

        for (const [personId, personData] of Object.entries(people)) {
          if (!personDeliverables[stageId][personId]) {
            personDeliverables[stageId][personId] = [];
          }

          const { training, allocation } = personData;

          while (personDeliverables[stageId][personId].length < allocation) {
            let deliverableId;
            if (isTrainingStage) {
              // if training, splice from the previous stages available training deliverable ids
              deliverableId = assignmentsTree[
                stages[index - 1].stageId
              ].trainingDeliverableIds.splice(0, 1)[0];
            } else {
              if (index === 0 || !randomised[stageId]) {
                deliverableId = availableDeliverables.splice(0, 1)[0];
                if (deliverableId) {
                  personDeliverables[stageId][personId].push(deliverableId);
                  usedInCurrentStage.add(deliverableId);
                }
              } else {
                const prevPersonDeliverables =
                  personDeliverables[previousProductionStageId]?.[personId] ||
                  [];

                for (let j = 0; j < availableDeliverables.length; j++) {
                  const currentDel = availableDeliverables[j];

                  if (
                    !prevPersonDeliverables.includes(currentDel) &&
                    !usedInCurrentStage.has(currentDel)
                  ) {
                    deliverableId = currentDel;
                    break;
                  }
                }

                // If no suitable deliverable found, try swapping
                if (!deliverableId && availableDeliverables.length > 0) {
                  for (let otherPersonId in personDeliverables[stageId]) {
                    if (otherPersonId === personId) continue;

                    const otherPersonDeliverables =
                      personDeliverables[stageId][otherPersonId];
                    const otherPrevDeliverables =
                      personDeliverables[previousProductionStageId]?.[
                        otherPersonId
                      ] || [];

                    for (let j = 0; j < otherPersonDeliverables.length; j++) {
                      const otherDel = otherPersonDeliverables[j];
                      if (!prevPersonDeliverables.includes(otherDel)) {
                        // Found a deliverable we can swap
                        const ourDel = availableDeliverables[0];
                        if (!otherPrevDeliverables.includes(ourDel)) {
                          // Perform the swap
                          otherPersonDeliverables[j] = ourDel;
                          deliverableId = otherDel;
                          availableDeliverables[0] = deliverableId;

                          const otherAssignmentIndex =
                            stageAllocations.findIndex(
                              (a) =>
                                a.personId === otherPersonId &&
                                a.deliverableId === otherDel
                            );
                          if (otherAssignmentIndex !== -1) {
                            stageAllocations[
                              otherAssignmentIndex
                            ].deliverableId = ourDel;
                          }
                          break;
                        }
                      }
                    }
                    if (deliverableId) break;
                  }
                }

                // If still no deliverable, take the first available as last resort
                // Shouldn't be needed as we should have found a deliverable by now
                if (!deliverableId && availableDeliverables.length > 0) {
                  deliverableId = availableDeliverables[0];
                }

                if (deliverableId) {
                  personDeliverables[stageId][personId].push(deliverableId);
                  usedInCurrentStage.add(deliverableId);
                  availableDeliverables.splice(
                    availableDeliverables.indexOf(deliverableId),
                    1
                  );
                }
              }

              const rate = getRate({
                deliverableRatesMap,
                deliverableId,
                quotedRates,
                deliverables,
                personId,
                stageId,
                langCode,
                language: people,
              });

              stageAllocations.push({
                deliverableId,
                personId,
                rate,
                stageId,
                deadline: formatDateObject(
                  convertDate(deadlines[stageId], true)
                ),
                inTraining: training,
                languageCode: langCode,
              });

              // if the current assignment is not training and it has training flag, then add this deliverableId for the training stage
              if (!isTrainingStage && training) {
                assignmentsTree[stageId].trainingDeliverableIds.push(
                  deliverableId
                );
              }
            }
          }
        }
      });

    if (!randomised[stageId] && index !== 0) {
      stageAllocations.sort((a, b) => {
        return (
          orderedAllocatedDeliverables.indexOf(a.deliverableId) -
          orderedAllocatedDeliverables.indexOf(b.deliverableId)
        );
      });
    }

    assignmentsTree[stageId].assignments.push(...stageAllocations);

    if (index === 0) {
      orderedAllocatedDeliverables = stageAllocations.map(
        (item) => item.deliverableId
      );
    }
  });

  // build an array of assignments from the tree
  const stageIds = Object.keys(assignmentsTree);
  const assignments = stageIds.reduce((acc, key) => {
    acc.push(...assignmentsTree[key].assignments);
    return acc;
  }, []);

  return async (dispatch, getState) => {
    const state = getState();
    const { workflowId } = state.projects.entities[projectId] || {};
    const { workflowName, workflowType } =
      state.workflows.entities[workflowId] || {};

    const query = `mutation createAssignments($assignments: [AssignmentInput]) {
      createAssignments(assignments: $assignments) {
        assignmentId, stageId, personId, deliverableId, rate, deadline, status
      }
    }`;

    try {
      const json = await postGraphQL(
        query,
        { assignments },
        "createAssignments"
      );
      dispatch(createAssignmentsSuccess(json));

      const tree = assignments.reduce((acc, { deliverableId }) => {
        acc[deliverableId] = true;
        return acc;
      }, {});
      const deliverableIds = Object.keys(tree);

      const allocatedDeliverables = deliverableIds.map((deliverableId) => ({
        deliverableId: Number(deliverableId),
        allocated: true,
      }));
      dispatch(updateDeliverablesSuccess(allocatedDeliverables));

      if (AI_WORKFLOW_NAMES.includes(workflowName)) {
        const newQuery = `mutation generateContent($deliverableIds: [Int!], $workflowType: String) {
          generateContent(deliverableIds: $deliverableIds, workflowType: $workflowType)
        }`;
        const json = await postGraphQL(
          newQuery,
          { deliverableIds, workflowType },
          "generateContent"
        );
        dispatch(createAssignmentsSuccess(json));
      }

      const url = `/admin/projects/${projectId}`;
      history.push(url);
    } catch (err) {
      handleErrors(err);
    }
  };
}
